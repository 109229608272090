<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />
    </div>
    <div class="px-2 mt-1">
      <div class="card card-height">
        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Incentive Type</label>
              <v-select
                  placeholder="Select Incentive Type"
                  v-model="formData.incentive_type"
                  :options="incentiveType"
                  :reduce="name => name.value"
                  label="name"
              />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label for="colFormLabel">
                Product/Service
              </label>
              <AsyncSelect
                  placeholder="Select Product/Service"
                  :api-service="fetchProductList"
                  v-model="product"
                  label="text"
                  :format-label="option => option.text"
                  :additional-query="{ is_all: true }"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Percentage</label>
              <input type="text" placeholder="Enter Percentage" v-model="formData.percentage" class="form-control">
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Level Name</label>
              <input type="text" placeholder="Enter Level Name" v-model="formData.level_name" class="form-control">
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Equivalent Designation</label>
              <input type="text" placeholder="Enter Equivalent Designation" v-model="formData.equivalent_designation" class="form-control">
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Commission(%)</label>
              <input type="text" placeholder="Enter Commission" v-model="formData.commission" class="form-control">
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Qualify Team Level</label>
              <v-select
                  placeholder="Select Qualify Team Level"
                  v-model="formData.qualify_team_level_id"
                  :options="incentiveSetupList"
                  label="level_name"
                  :reduce="name => name.id"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Qualify Team Level Qty</label>
              <input type="text" placeholder="Enter Qualify Team Level Qty" v-model="formData.qualify_team_level_qty" class="form-control">
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">SalesTarget Criteria</label>
              <input type="text" placeholder="Enter SalesTarget Criteria" v-model="formData.sales_target" class="form-control">
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Company Sales Share Type</label>
              <v-select
                  placeholder="Select Company Sales Share Type"
                  v-model="formData.company_sale_share_type"
                  :options="incentiveCompanySaleShareType"
                  label="name"
                  :reduce="name => name.value"
              />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
              <label class="align-middle">Company Sales Share (%)</label>
              <input type="text" placeholder="Enter Company Sales Share %" v-model="formData.company_sale_share_percentage" class="form-control">
            </div>

            <div v-if="!isCreate" class="col-12 col-sm-6 col-md-4 col-lg-4  mb-1">
              <label class="align-middle">Status</label>
              <v-select
                  placeholder="Select Status"
                  v-model="formData.status"
                  :options="status"
                  label="name"
                  :reduce="name => name.value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveAP(false)"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";
import handleInventory from "@/services/modules/inventory";
import handlePurchase from '@/services/modules/purchase';
import AsyncSelect from '@/components/molecule/input-field/AsyncSelect.vue';
import handleHospital from "@/services/modules/hospital";

const store = useStore();
const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("Add Incentive Setup")
let contacts = ref([])
let locations = ref([])
let incentiveSetupList = ref([])
let accountPayable = ref([])
const product = ref('');
let incentiveSetupEditData = ref({})
const status = ref([]);
const incentiveType = ref([]);
const incentiveCompanySaleShareType = ref([]);
let incentiveData = {}
let formData = ref({
  company_id: companyId,
  status: 'active',
  product_id:'',
  percentage:'',
  incentive_type:'',
  level_name:'',
  equivalent_designation:'',
  commission:'',
  qualify_team_level_id:'',
  qualify_team_level_qty:'',
  sales_target:'',
  company_sale_share_type:'',
  company_sale_share_percentage:'',
})

function navigateToListPage() {
  delete route.query.incentiveSetupId;
  router.push({name: `incentive-setup`, params: route.params, query: route.query});
}

const { fetchHome } = handleInventory()
const { storeIncentiveSetup, fetchSingleIncentiveSetup, updateIncentiveSetup, fetchIncentiveSetupList } = handleHospital()
const { fetchProductList } = handlePurchase();
function saveAP(redirect = true) {
  if (isCreate.value) {
    handleSubmit(redirect);
  } else {
    handleUpdate(redirect);
  }
}

const getIncentiveSetupList = async () => {
  let query = getQuery();
  query += '&incentive_type=level';
  query += '&status=active';
  query += '&is_all=true';
  incentiveSetupList.value = []

  await fetchIncentiveSetupList(query).then(res => {
    if (!res.status){
      return
    }
    incentiveSetupList.value = res.data;
  }).catch(err => {
    console.log(err)
  })
}

function handleSubmit(redirect) {
  saveButtonLoader.value = true
  incentiveData.company_id  = formData.value.company_id;
  incentiveData.status = "active";
  incentiveData.incentive_type = formData.value.incentive_type;
  incentiveData.product_id = product.value ? product.value.id : '';
  incentiveData.percentage = formData.value.percentage;
  incentiveData.level_name = formData.value.level_name;
  incentiveData.equivalent_designation = formData.value.equivalent_designation;
  incentiveData.commission = formData.value.commission;
  incentiveData.qualify_team_level_id = formData.value.qualify_team_level_id;
  incentiveData.qualify_team_level_qty = formData.value.qualify_team_level_qty;
  incentiveData.sales_target = formData.value.sales_target;
  incentiveData.company_sale_share_type = formData.value.company_sale_share_type;
  incentiveData.company_sale_share_percentage = formData.value.company_sale_share_percentage;
  createMT(redirect);
}

function handleUpdate(redirect) {
  saveButtonLoader.value = true
  incentiveData.id = formData.value.id;
  incentiveData.company_id  = formData.value.company_id;
  incentiveData.status = formData.value.status;
  incentiveData.incentive_type = formData.value.incentive_type;
  incentiveData.product_id = product.value ? product.value.id : '';
  incentiveData.percentage = formData.value.percentage;
  incentiveData.level_name = formData.value.level_name;
  incentiveData.equivalent_designation = formData.value.equivalent_designation;
  incentiveData.commission = formData.value.commission;
  incentiveData.qualify_team_level_id = formData.value.qualify_team_level_id;
  incentiveData.qualify_team_level_qty = formData.value.qualify_team_level_qty;
  incentiveData.sales_target = formData.value.sales_target;
  incentiveData.company_sale_share_type = formData.value.company_sale_share_type;
  incentiveData.company_sale_share_percentage = formData.value.company_sale_share_percentage;
  updateMTData(redirect);
}

function createMT(redirect = true) {
  storeIncentiveSetup(incentiveData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message)
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
        saveButtonLoader.value = false
      })
}

function updateMTData(redirect = true) {
  updateIncentiveSetup(incentiveData, incentiveData.id, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
         showSuccess(res.message);
         if (redirect) navigateToListPage()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
        saveButtonLoader.value = false
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  headerTitle.value = "Update Incentive Setup";
  formData.value.id =  incentiveSetupEditData.value.id;
  formData.value.product_id = incentiveSetupEditData.value.product_id;
  product.value = incentiveSetupEditData.value.product;
  formData.value.percentage = incentiveSetupEditData.value.percentage;
  formData.value.incentive_type = incentiveSetupEditData.value.incentive_type;
  formData.value.status = incentiveSetupEditData.value.status;
  formData.value.level_name = incentiveSetupEditData.value.level_name;
  formData.value.equivalent_designation = incentiveSetupEditData.value.equivalent_designation;
  formData.value.commission = incentiveSetupEditData.value.commission;
  formData.value.qualify_team_level_id = incentiveSetupEditData.value.qualify_team_level_id;
  formData.value.qualify_team_level_qty = incentiveSetupEditData.value.qualify_team_level_qty;
  formData.value.sales_target = incentiveSetupEditData.value.sales_target;
  formData.value.company_sale_share_type = incentiveSetupEditData.value.company_sale_share_type;
  formData.value.company_sale_share_percentage = incentiveSetupEditData.value.company_sale_share_percentage;
}

const getTypes = async () => {
  let query = getQuery();
  await fetchHome(query).then(res => {
    if (!res.status){
      status.value = [];
      incentiveType.value = [];
      return
    }
    if (res.data && res.data.status) {
      status.value = Object.keys(res.data.status)
          .filter(key => ["active", "inactive"].includes(key))
          .map(key => ({
            value: key,
            name: res.data.status[key]
          }));
    }
    if (res.data && res.data.incentive_type) {
      incentiveType.value = Object.keys(res.data.incentive_type)
          .map(key => ({
            value: key,
            name: res.data.incentive_type[key]
          }));
    }

    if (res.data && res.data.incentive_company_sale_share_type) {
      incentiveCompanySaleShareType.value = Object.keys(res.data.incentive_company_sale_share_type)
          .map(key => ({
            value: key,
            name: res.data.incentive_company_sale_share_type[key]
          }));
    }

  }).catch(err => {
    console.log(err)
  })
}

function onPageReload() {
  loader.value=true
  Promise.all([
    getTypes(),
    getIncentiveSetupList(),

  ]).finally(() => {
    loader.value = false
  })

  if(route.query.incentiveSetupId && route.query.incentiveSetupId !== '' && route.query.incentiveSetupId !== undefined){
    isCreate.value = false;
    const fetchSingleData = fetchSingleIncentiveSetup(route.query.incentiveSetupId, getQuery());
    fetchSingleData.then(res2 => {
      incentiveSetupEditData.value = res2.data;
      setFormData();
    });
  }
}

onMounted(() => {
  onPageReload();
})
</script>